import React from 'react'
import { Link } from 'gatsby'

const Subnav = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    return (
    	<>
    	<div className="container is-clearfix is-hidden-mobile sub-nav">
		  <ul role="navigation" aria-label="main-navigation" >
			<li className="sub-title is-pulled-left"><span>Guides:</span></li>
			{this.props.guides.map((guide, index) =>
				<li key={`guide-`+index}>
					<Link to={guide.link}>{guide.title}</Link>
				</li>
			)}
		  </ul>
		</div>
		
		<div className="is-hidden-desktop mobile-sub-nav">
			<div className="select is-fullwidth is-rounded">
				<select onChange={(e)=> { window.location.href = e.target.value }}>
					<option value="" selected="selected">More Guides</option>
					{this.props.guides.map((guide, index) =>
					<option key={`guide-`+index} value={guide.link}>
						{guide.title}
					</option>
				)}
				</select>
			</div>
		</div>
		
		</>
    )
  }
}

export default Subnav
