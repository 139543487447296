import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Subnav from '../components/Subnav'

const remark = require(`remark`)
const html = require(`remark-html`)

export const GuidePageTemplate = ({ title, image, content, entryUrl, guides }) => (
	<div id="page-wrapper">
		<div className="hero" style={{ backgroundImage: `url(${  !!image.childImageSharp ? image.childImageSharp.fluid.src : image })` }}>
			<div className="overlay guide"></div>
			<Navbar entryUrl={entryUrl} />
			<div className="container">
				<div className="content">
					<h1>{title}</h1>
				</div>
			</div>
		</div>
		
		 <div className="container std-content" >
		 	<Subnav guides={guides} />
		 	<div className="copy"><div dangerouslySetInnerHTML={{ __html: content }} /></div>
		 </div>
  </div>
)

const GuidePage = ({ data }) => {
  
  const { markdownRemark: guide } = data
  const { frontmatter } = data.markdownRemark
  //const info = remark().use(html).processSync(frontmatter.information).toString()

  return (
    <Layout title={frontmatter.title} description={`Taking part in the Santa dash this year? Find your guide to ${frontmatter.title} here.`}>
      <GuidePageTemplate
	  	title={frontmatter.title}
	  	image={frontmatter.image}
	  	content={guide.html}
	  	entryUrl={data.index.frontmatter.entryUrl}
	  	guides={data.index.frontmatter.training.guides}
      />
    </Layout>
  )
}

export default GuidePage

export const pageQuery = graphql`
  query GuidePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
			childImageSharp {
				fluid(maxWidth: 2048, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
      }
    }
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				entryUrl
				training {
				guides {
					title
					link
				}
				}
      }
  }
  }
`
